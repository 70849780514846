import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {mapStateToProps} from "../store/helpers/mapState";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import {downloadBase64Get} from "../store/helpers/common_functions";
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PdfViewerComponent from "../components/PdfViewerComponent";
import {Spinner} from "react-bootstrap";
import CustomDocumentViewer from "../components/document_viewer";

const DocumentView = ({FetchDetails,SubmitGeneralForm,path,setPageNum,info,references,highlight,fileId}) => {
    const [base64,setBase64] = React.useState();
    const [loading,setLoading] = React.useState(false);
    const [ip, setIp] = useState();

    // const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const {id} = useParams();

    const getIp = async ()=>{
        try {
            const response = await fetch('https://geolocation-db.com/json/');
            const data = await response.json();
            setIp(data.IPv4);
            submitVisit(data.IPv4).then();
            return data.IPv4;
        }catch (e) {
            console.log(e);
        }
    }

    const submitVisit = async (ip)=>{
        await SubmitGeneralForm("visitors/create",{
            id,
            ip,
        });
    }

    const downloadBase = async ()=>{
        setLoading(true);
        const base = await downloadBase64Get(path,"application/pdf");
        setLoading(false);
        if(base) {
            setBase64(base);
        }else{
            // setPath(null);
        }
    }






    useEffect(()=>{
        if(path){
            downloadBase().then();
        }
    },[path]);

    useEffect(()=>{
        getIp().then();
    },[]);

    return <div style={{marginTop:"23px"}}>
        {/*{loading ? <Spinner/> : base64 ? <PdfViewerComponent document={base64}/> : null}*/}
        {loading ? <div className={'d-flex align-items-center justify-content-center'} style={{minHeight:"82vh"}}>
            <Spinner/>
        </div> : base64 ? <CustomDocumentViewer
            FetchDetails={FetchDetails}
            references={references}
            fileUrl={base64}
            setPageNum={setPageNum}
            highlight={highlight}
            fileId={fileId}
            info={info}/> : null}
        {/*{loading ? <div className={'p-5'}><span>Loading...</span></div> : base64 ?*/}
        {/*    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">*/}
        {/*    <Viewer fileUrl={base64}*/}
        {/*            plugins={[*/}
        {/*                // Register plugins*/}
        {/*                defaultLayoutPluginInstance,*/}
        {/*            ]}/>*/}
        {/*</Worker> : null}*/}
    </div>;
}

export default connect(mapStateToProps,mapDispatchToProps)(DocumentView);
