import {connect} from "react-redux";
import {mapStateToProps} from "../store/helpers/mapState";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import {useEffect, useRef, useState} from "react";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import AdvancedSearch from "./AdvancedSearch";
import {Link, useSearchParams} from "react-router-dom";
import NoResultsFound from "../components/NoResultsFound";
import LoadingSpinner from "../components/LoadingSpinner";
import {useTranslation} from "react-i18next";
import DocumentSearchItem from "../components/DocumentSearchItem";
import PaginationComponent from "../components/pagination_item";
import {inArray} from "../store/helpers/common_functions";

const SearchResults = ({FetchDetails,sections,showAdvanced}) => {
    const [loading,setLoading] = useState(false);
    const [results,setResults] = useState([]);
    const [active,setActive] = useState(null);
    const divRef = useRef(null);
    const [activeChild,setActiveChild] = useState(null);
    const [activeGChild,setActiveGChild] = useState(null);
    const [count, setCount] = useState({});
    const [limit] = useState(10);
    const [start, setStart] = useState(1);
    const [total, setTotal] = useState(0);
    const {i18n,t} = useTranslation();
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [collection, setCollection] = useState();
    const [volume, setVolume] = useState();
    const [status, setStatus] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [search, setSearch] = useState(null)
    const [tempSearch, setTempSearch] = useState(null)
    const [ignoreSectionParam, setIgnoreSectionParam] = useState(false);

    const [searchParams] = useSearchParams();
    const text = searchParams.get("text");
    const qSearch = searchParams.get("search");
    const keyword = searchParams.get("keyword");
    const section = searchParams.get("section");
    const qCategory = searchParams.get("category");
    const qVolume = searchParams.get("volume");
    const year = searchParams.get("year");
    const qSubCategory = searchParams.get("sub_category");
    const date = searchParams.get("date");
    const qCollection = searchParams.get("collection");
    const qAdvanced = searchParams.get("advanced");
    const month = searchParams.get("month");
    const qStatus = searchParams.get("status");

    const getActiveId = () => activeGChild?.id ?? activeChild?.id ?? active?.id;
    const getSearchResults = async ()=>{
        setLoading(true);

        let url = `documents/search?start=${(start-1)*limit}&length=${limit}&language=${i18n.language}`;
        if(text){
            url = `${url}&name=${text}`;
        }
        if(search){
            url = `${url}&search=${search}`;
        }
        if(keyword){
            url = `${url}&keywords=${keyword}`;
        }
        if(qAdvanced){
            url = `${url}&advanced=${qAdvanced}`;
        }

        const activeId = getActiveId();
        let newSection = activeId || ignoreSectionParam ? activeId : section;
        const isSectionOne = inArray(["1.1","1.2","1",undefined],newSection);
        if(category && isSectionOne){
            url = `${url}&category=${category}`;
        }
        if(subCategory && isSectionOne){
            url = `${url}&sub_category=${subCategory}`;
        }
        if(volume && inArray(["2.2.1","2.2",undefined],newSection)){
            url = `${url}&volume=${volume}`;
        }
        if(startDate){
            url = `${url}&startDate=${startDate}`;
        }
        if(endDate){
            url = `${url}&endDate=${endDate}`;
        }
        if(date && month && year){
            url = `${url}&date=${year}-${month}-${date}`;
        }else if(month && year){
            url = `${url}&month=${month}&year=${year}`;
        }else if(year){
            url = `${url}&year=${year}`;
        }
        if(collection && inArray(["2.1","2.2.1","2.2.2","2.2.3","2",undefined],newSection)){
            url = `${url}&collection=${collection}`;
        }
        if(status && inArray(["2.1","2",undefined],newSection)){
            url = `${url}&decisionsStatus=${status}`;
        }

        if(newSection){
            let param = "section";
            if(newSection === '1'){
                newSection = '1.1,1.2';
                param = "sections";
            }
            if(newSection === '2'){
                newSection = '2.1,2.2.1,2.2.2,2.2.3';
                param = "sections";
            }
            if(newSection === '2.2'){
                newSection = '2.2.1,2.2.2,2.2.3';
                param = "sections";
            }
            url = `${url}&${param}=${newSection}`;
        }

        const resp = await FetchDetails(url,true);
        if(resp.status){
            const data = resp.data.data;
            setResults(data.data ? data.data : []);
            setCount(data.counts);
            setTotal(data.recordsFiltered);
        }
        setLoading(false);
    }


    useEffect(() => {
        setSearch(qSearch);
        setTempSearch(qSearch);
    }, [qSearch]);
    const scrollToDiv = () => {
        divRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        if(loading){
            scrollToDiv();
        }
    }, [loading]);


    useEffect(()=>{
            getSearchResults().then();
    },[text,keyword,ignoreSectionParam,qAdvanced,section,category,subCategory,volume,startDate,endDate,status,date,month,year,collection,search,i18n.language,start,active,activeChild,activeGChild]);

    useEffect(() => {
        setCategory(qCategory);
    }, [qCategory]);
    useEffect(() => {
        setSubCategory(qSubCategory);
    }, [qSubCategory]);
    useEffect(() => {
        setCollection(qCollection);
    }, [qCollection]);
    useEffect(() => {
        setVolume(qVolume);
    }, [qVolume]);
    useEffect(() => {
        setStatus(qStatus);
    }, [qStatus]);

    useEffect(()=>{
        if(sections.length){
            let newSection = sections[0];
            if (section){
                newSection = sections.find(v=>v.id === section || v.children?.find(child=>child.id === section || child.children?.find(gChild=>gChild.id === section))) ?? newSection;
            }
            // setActive(newSection);
            if(newSection?.children?.length){
                let child = newSection.children[0];
                if(section) {
                    child = newSection.children.find(v => v.id === section || v.children?.find(child => child.id === section)) ?? child;
                }
                // setActiveChild(child);
                if(child?.children?.length){
                    let gChild = child.children[0];
                    if(section){
                        gChild = child.children.find(v => v.id === section) ?? gChild;
                    }
                    // setActiveGChild(gChild);
                }else{
                    setActiveGChild(null);
                }
            }else{
                setActiveChild(null);
                setActiveGChild(null);
            }
        }
    },[sections]);


    const id = activeGChild?.id ?? activeChild?.id ?? active?.id;

    // console.log(id);

    useEffect(()=>{
        setStart(1);
    },[id]);

    let total2 = count['2.2.1'] + count['2.2.2'] + count['2.2.3'];
    const legislationCount = count['1.1'] + count['1.2'];
    const caseCount = count['2.1'] + total2;
    let totalSections = caseCount + legislationCount;


    return <div className={'position-relative'}>
        {loading && <div style={{
            background:"rgba(255,255,255,0.5)",
            zIndex:12
        }} className={'position-absolute top-0 left-0 w-100 h-100 d-flex justify-content-center align-items-center'}>
            <LoadingSpinner/>
        </div>}


        <Row className={'justify-content-center'}>
            <Col md={9}>
                <form action="/search/results" onSubmit={(e)=>{
                    e.preventDefault();
                    setSearch(tempSearch);
                }} method={'get'} style={{marginTop:"50px"}}>
                    <InputGroup className="mb-1 position-relative rounded-4 overflow-hidden"
                                style={{border:"3px #63866F solid"}}>
                        <div className={'position-absolute'} style={{zIndex:10,top:"14px",left:"10px"}}>
                            <img src={require('./../assets/search_icon.png')} style={{width:"30px",height:"30px"}} alt=""/>
                        </div>
                        <Form.Control
                            placeholder={t('search')}
                            aria-label="Search"
                            name={'search'}
                            aria-describedby="basic-addon2"
                            className={'p-3 border-0 outline-0 ps-5'}
                            required={true}
                            value={tempSearch}
                            onChange={(e)=>{
                                setTempSearch(e.target.value);
                            }}
                            minLength={3}
                        />
                        <Button variant="secondary" type={'submit'} id="button-addon2" className={'rounded-3 px-5'}
                                style={{borderColor:"#63866F",backgroundColor:"#63866F",borderWidth:"3px"}}>
                            {t('search_')}
                        </Button>
                    </InputGroup>
                </form>
            </Col>
        </Row>
        <Row className={'mt-3'}>
            <div className={'my-2'}>
                <h3><i className={'bi bi-search'}></i> Search Results</h3>
            </div>
            <Col md={showAdvanced ? 5 : 4}>
                {<AdvancedSearch partial={true} showAdvanced={showAdvanced} currentSection={id} onFilter={(obj)=>{
                    if(obj.hasOwnProperty("category")){
                        setCategory(obj.category);
                    }
                    if(obj.hasOwnProperty("subCategory")){
                        setSubCategory(obj.subCategory);
                    }
                    if(obj.hasOwnProperty("collection")){
                        setCollection(obj.collection);
                    }
                    if(obj.hasOwnProperty("volume")){
                        setVolume(obj.volume);
                    }
                    if(obj.hasOwnProperty("status")){
                        setStatus(obj.status);
                    }
                    if(obj.hasOwnProperty("startDate")){
                        setStartDate(obj.startDate);
                    }
                    if(obj.hasOwnProperty("endDate")){
                        setEndDate(obj.endDate);
                    }
                }}/>}
            </Col>
            <Col md={showAdvanced ? 7 : 8}>
                <div className={'pb-5'}>
                    <div  className={'d-flex p-3 rounded-top-3 justify-content-center'} style={{backgroundColor:"#63866F"}}>
                        <Link onClick={(e)=>{
                            e.preventDefault();
                            setActive(null);
                                setActiveChild(null);
                            setActiveGChild(null);
                            setIgnoreSectionParam(true);
                        }} to={''} className={`nav-custom-pill text-decoration-none rounded p-1 px-3 ${active == null ? 'active' : ''}`} style={{fontSize:"20px",color:"white"}}>
                            <i className={'bi bi-bar-chart-fill'}></i>
                            <span className={'ms-2'}>{t('all')}</span>
                            {totalSections>0 && <span className={'badge bg-success ms-2'}>{totalSections}</span>}
                        </Link>
                        {
                            sections.map((v)=>{
                                let label,icon,href,counter=0;
                                switch (v.id) {
                                    case "1":
                                        icon = "bi bi-building";
                                        href = `in-force`;
                                        counter = legislationCount;
                                        break;
                                    case "2":
                                        icon = "bi bi-diagram-2";
                                        href = "judgement";
                                        counter = caseCount;
                                        break;
                                    default:
                                        icon = "";
                                        break;
                                }
                                switch (i18n.language){
                                    case "en":
                                        label = v['name_en'];
                                        break;
                                    case "fr":
                                        label = v['name_fr'];
                                        break;
                                    case "rw":
                                        label = v['name_rw'];
                                        break;
                                    default:
                                        label = "";
                                }
                                return <Link onClick={(e)=>{
                                    e.preventDefault();
                                    setActive(v);
                                    // if(v.children?.length){
                                    //     setActiveChild(v.children[0]);
                                    // }else{
                                        setActiveChild(null);
                                    // }
                                    setActiveGChild(null);
                                }} to={''} className={`nav-custom-pill text-decoration-none rounded p-1 px-3 ${active?.id === v.id ? 'active' : ''}`} style={{fontSize:"20px",color:"white"}}>
                                    <i className={icon}></i>
                                    <span className={'ms-2'}>{label}</span>
                                    {counter>0 && <span className={'badge bg-success ms-2'}>{counter}</span>}
                                </Link>
                            })
                        }
                    </div>

                    <div  className={'card-body p-3'}>
                        {active != null && <div className={'d-flex mb-3'}>
                            <Link to={''} onClick={e => {
                                e.preventDefault();
                                setActiveChild(null);
                                setActiveGChild(null);
                            }}
                                  className={`nav-custom-pill text-decoration-none rounded shadow-sm me-2 p-1 px-3 fw-bold ${activeChild === null ? 'active' : ''}`}
                                  style={{fontSize: "15px", color: "#3A4F39"}}>
                                <span className={'ms-2'}>{t('all')}</span>
                                {<span className={'badge bg-success ms-2'}>{active.id === '1' ? legislationCount : caseCount}</span>}
                            </Link>
                            {
                                active.children?.map((v) => {
                                    let label, icon, href, counter = 0;
                                    switch (v.id) {
                                        case "1.1":
                                            counter = count['1.1'];
                                            break;
                                        case "1.2":
                                            counter = count['1.2'];
                                            break;
                                        case "2.1":
                                            icon = "bi bi-building";
                                            href = `/laws/judgement/${active?.id}`;
                                            counter = count['2.1'];
                                            break;
                                        case "2.2":
                                            icon = "bi bi-building";
                                            href = `/laws/report/${active?.id}`;
                                            counter = count['2.2.1'] + count['2.2.2'] + count['2.2.3'];
                                            break;
                                        default:
                                            icon = "";
                                            href = "";
                                            break;
                                    }
                                    switch (i18n.language) {
                                        case "en":
                                            label = v['name_en'];
                                            break;
                                        case "fr":
                                            label = v['name_fr'];
                                            break;
                                        case "rw":
                                            label = v['name_rw'];
                                            break;
                                        default:
                                            label = "";
                                    }
                                    return <Link onClick={(e) => {
                                        e.preventDefault();
                                        setActiveChild(v);
                                        // if (v.children?.length) {
                                        //     setActiveGChild(v.children[0]);
                                        // } else {
                                            setActiveGChild(null);
                                        // }
                                    }} to={`${href}?child=${v.id}`}
                                                 className={`nav-custom-pill text-decoration-none rounded shadow-sm me-2 p-1 px-3 ${activeChild?.id === v.id ? 'active' : ''}`}
                                                 style={{fontSize: "15px", color: "#3A4F39"}}>
                                        <i className={icon}></i>
                                        <span className={'ms-2'}>{label}</span>
                                        {<span className={'badge bg-success ms-2'}>{counter}</span>}
                                    </Link>
                                })
                            }
                        </div>}
                    {activeChild?.children?.length>0 && <div>
                        <div className={'d-flex mb-3'}>
                            {activeChild !== null && <Link to={''} onClick={e=>{
                                e.preventDefault();
                                setActiveGChild(null);
                            }}
                                className={`nav-custom-pill text-decoration-none rounded shadow-sm me-2 p-1 px-3 fw-bold ${activeGChild === null ? 'active' : ''}`}
                                style={{fontSize: "15px", color: "#3A4F39"}}>
                        <span className={'ms-2'}>{t('all')}</span>
                                {<span className={'badge bg-success ms-2'}>{total2}</span>}
                    </Link>}
                            {
                                activeChild?.children?.map((v,index)=>{
                                    let label,icon,href,counter=0;
                                    switch (v.id) {
                                        case "2.2.1":
                                            icon = "bi bi-building";
                                            href = `in-force`;
                                            counter = count['2.2.1'];
                                            break;
                                        case "2.2.2":
                                            icon = "bi bi-diagram-2";
                                            href = "report";
                                            counter = count['2.2.2'];
                                            break;
                                        case "2.2.3":
                                            icon = "bi bi-book";
                                            href = "judgement";
                                            counter = count['2.2.3'];
                                            break;
                                        default:
                                            icon = "";
                                            break;
                                    }
                                    switch (i18n.language){
                                        case "en":
                                            label = v['name_en'];
                                            break;
                                        case "fr":
                                            label = v['name_fr'];
                                            break;
                                        case "rw":
                                            label = v['name_rw'];
                                            break;
                                        default:
                                            label = "";
                                    }
                                    return <Link onClick={(e)=>{
                                        e.preventDefault();
                                        setActiveGChild(v);
                                    }} to={`?child=${activeGChild?.id}&g-child=${v.id}`} className={`nav-custom-pill text-decoration-none rounded shadow-sm me-2 p-1 px-3 ${activeGChild?.id === v.id ? 'active' : ''}`} style={{fontSize:"15px",color:"#3A4F39"}}>
                                        <i className={icon}></i>
                                        <span className={'ms-2'}>{label}</span>
                                        {<span className={'badge bg-success ms-2'}>{counter}</span>}
                                    </Link>
                                })
                            }
                        </div>
                    </div>}
                    <div ref={divRef}>
                        {id && !results.length ? <NoResultsFound/> : results.filter(v=>v?._source?.document_section === id || !id || (id === '1' && inArray(["1.1","1.2"],v?._source?.document_section)) || (id === '2' && inArray(["2.1","2.2.1","2.2.2","2.2.3"],v?._source?.document_section)) || (id === '2.2' && inArray(["2.2.1","2.2.2","2.2.3"],v?._source?.document_section))).map(v=><DocumentSearchItem item={v} search={qSearch??""}/>)}

                        <PaginationComponent onChange={setStart} limit={limit} active={start} total={total}/>
                    </div>
                    </div>
                </div>
            </Col>
        </Row>
    </div>;
}

export default connect(mapStateToProps,mapDispatchToProps)(SearchResults);
